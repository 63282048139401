import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    '@global': {
      '.ant-layout-content': {
        margin: [12, 12, 0, 12],
        padding: [0, 12],
        background: '#fff',
      },
      '.ant-layout-footer': {
        padding: [12, 12],
      },
      '.ReactVirtualized__Grid': {
        outline: 'none',
      },
    },
  },
  channelInfoContainer: {
    display: 'flex',
    width: '100%',
    '& .channelInfo': {
      flex: 'auto',
    },
  },
});

export default useStyles;

import Twilio from 'twilio-chat';
import ChatWrapper from './wrapper';
import * as network from './network';
import * as logger from './logger';

class Negobot {
  constructor(svcNego) {
    this.svcNego = svcNego;
  }

  /**
   *
   * @param {Object} chatInfo
   * @param {string} chatInfo.apiKey
   * @param {string} chatInfo.token
   * @param {string} chatInfo.identity
   * @param {*} chatInfo.userInfo
   */
  async getChat({ apiKey, token, identity, userInfo }) {
    const url = network.makeUrl(this.svcNego, 'auth/token');
    const response = await network.request(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ apiKey, token, identity, userInfo }),
    });
    const { error } = response;
    if (error) {
      logger.log('Negobot SDK error:', error);
      return null;
    }
    const { data } = response;
    const { token: twilio } = data;
    try {
      const client = await Twilio.create(twilio);
      return ChatWrapper(client);
    } catch (ex) {
      logger.log('Negobot SDK error:', ex);
      return null;
    }
  }
}

export default Negobot;

import * as actions from './redux/actions';
import * as services from './services';

export default {
  namespace: 'home',
  state: {
    controlStatus: true, // bot control status
    controlState: '',
  },
  effects: {
    *changeControlStatus(action, { put, call }) {
      const response = yield call(services.takeControl, action.payload);
      const { error } = response;
      if (error) {
        yield put(actions.changeControlStatusFail('Fail to take control from the Bot'));
        return;
      }
      const { data } = response;
      yield put(actions.changeControlStatusSuccess(data));
    },
  },
  reducers: {
    cleanupControlStatus: (state) => {
      return { ...state, controlState: '', controlStatus: true };
    },
    changeControlStatusFail: (state) => {
      return { ...state, controlState: 'CHANGE_CONTROL_STATUS_FAIL' };
    },
    changeControlStatusSuccess: (state, action) => {
      return {
        ...state,
        controlStatus: action.payload.data.channel.status,
        controlState: 'CHANGE_CONTROL_STATUS_SUCCESS',
      };
    },
  },
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Layout as AntdLayout, Button } from 'antd';
import { ChannelInfo, MessageList, MessageInput } from '@mannegobot/nego-test-lib';
import { useChangeControlStatus } from '../../../hooks/useControl';
import useStyles from './styles';

const ChannelInfoWrapper = ({ sid, friendlyName, enableBot }) => {
  const classes = useStyles();
  const changeControlStatus = useChangeControlStatus();
  const onChangeControlStatusClick = () => {
    changeControlStatus({ channelSid: sid, status: !enableBot });
  };
  return (
    <div className={classes.channelInfoContainer}>
      <div className="channelInfo">
        <ChannelInfo friendlyName={friendlyName} />
      </div>
      <Button type="primary" onClick={onChangeControlStatusClick}>
        {enableBot ? 'Take Control' : 'Handover to Bot'}
      </Button>
    </div>
  );
};

const MessageListWrapper = ({
  user,
  // loadingMessages,
  messages,
  getMessages,
  sendMessage,
  getMoreMessages,
}) => {
  useEffect(() => {
    const initMessageList = async () => {
      await getMessages();
    };
    initMessageList();
  }, []);
  return (
    <MessageList
      me={user.identity}
      messages={messages}
      onMessageSend={sendMessage}
      loadMore={getMoreMessages}
    />
  );
};

const MessageInputWrapper = ({ sendMessage, disabled }) => {
  return <MessageInput handleSubmit={sendMessage} disabled={disabled} />;
};

const { Header, Content, Footer } = AntdLayout;
const Layout = ({
  sid,
  friendlyName,
  loadingMessages,
  getMessages,
  messages,
  sendMessage,
  getMoreMessages,
  user,
  // initial,
  attributes,
}) => {
  const classes = useStyles();
  const { enableBot } = attributes || {};
  return (
    <AntdLayout className={classes.root}>
      <Header>
        <ChannelInfoWrapper sid={sid} friendlyName={friendlyName} enableBot={enableBot} />
      </Header>
      <Content>
        <MessageListWrapper
          user={user}
          loadingMessages={loadingMessages}
          messages={messages}
          getMessages={getMessages}
          sendMessage={sendMessage}
          getMoreMessages={getMoreMessages}
        />
      </Content>
      <Footer>
        <MessageInputWrapper sendMessage={sendMessage} disabled={enableBot} />
      </Footer>
    </AntdLayout>
  );
};

export default Layout;

import React from 'react';
import { Spin, Result, Icon } from 'antd';
import { Channel } from '@mannegobot/nego-test-lib';
import Layout from './Layout';
import useStyles from './styles';

const MainContent = ({ loadingChannel, channel, user, initial }) => {
  const classes = useStyles();
  let content = null;
  if (loadingChannel) {
    const indicator = <Icon type="loading" spin />;
    content = (
      <div className={classes.spin}>
        <Spin indicator={indicator} />
      </div>
    );
  } else {
    content =
      channel && user ? (
        <Channel
          channel={channel}
          render={({
            sid,
            getMessages,
            loadingMessages,
            messages,
            sendMessage,
            getMoreMessages,
            attributes,
            friendlyName,
          }) => (
            <Layout
              sid={sid}
              initial={initial}
              user={user}
              getMessages={getMessages}
              loadingMessages={loadingMessages}
              messages={messages}
              sendMessage={sendMessage}
              getMoreMessages={getMoreMessages}
              friendlyName={friendlyName}
              attributes={attributes}
            />
          )}
        />
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the channel you visited does not exist."
        />
      );
  }
  return <div className={classes.root}>{content}</div>;
};

export default MainContent;

import dva from 'dva';
import createLoading from 'dva-loading';
import { createBrowserHistory } from 'history';
import AppRouter from '../routers';
import AppModel from '../models';
import HomeModel from '../pages/Home/model';

const create = () => {
  const app = dva({
    history: createBrowserHistory(),
  });

  app.use(createLoading());

  app.model(AppModel);
  app.model(HomeModel);

  return app;
};

export default () => {
  const app = create();
  app.router(AppRouter);
  const instance = app.start();
  return instance;
};

import { useDispatch, useSelector } from 'dva';
import * as actions from '../redux/actions';
import * as selectors from '../redux/selectors';

export const useControlStatus = () => {
  const controlStatus = useSelector(selectors.getControlStatus);
  return controlStatus;
};

export const useChangeControlStatus = () => {
  const dispatch = useDispatch();
  return ({ channelSid, status }) => {
    dispatch(actions.changeControlStatus({ channelSid, status }));
  };
};

export const useCleanupControlStatus = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch(actions.cleanupControlStatus());
  };
};

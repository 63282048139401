/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Spin, Icon } from 'antd';
import { Chat } from '@mannegobot/nego-test-lib';
import * as configs from '../../configs';
import * as storage from '../../storage';
import Negobot from '../../sdk';
import { Layout, SiderContent, MainContent } from './components';
import * as logRocket from '../../utils/logrocket';
import useStyles from './styles';

const Home = () => {
  const classes = useStyles();
  const [client, setClient] = useState(undefined);
  const [loading, setLoading] = useState({ isLoading: true, tip: '' });
  const [initial, setInitial] = useState(null);

  const onInitExtraParams = (event) => {
    console.log('onInitExtraParams', event.origin, event.data);
    if (event.data) {
      try {
        const data = JSON.parse(event.data);
        logRocket.identify('identity', { data });
        setInitial(data);
      } catch (ex) {
        console.log('onInitExtraParams error', ex);
      }
    }
  };

  useEffect(() => {
    const initSdk = async () => {
      const negobot = new Negobot(configs.getConfig('SVC_GCP'));
      setLoading({
        isLoading: true,
        tip: 'Initializing the chat...',
      });
      const token = storage.get('TOKEN');
      const chat = await negobot.getChat({
        token,
      });
      if (chat) {
        setClient(chat);
        setLoading({
          isLoading: false,
          tip: '',
        });
      } else {
        setClient(null);
        setLoading({
          isLoading: true,
          tip: 'Fail to init the chat instance, please contact the negobot admin!',
        });
      }
    };

    const initExtraParams = () => {
      window.addEventListener('message', onInitExtraParams, false);
    };
    initSdk();
    initExtraParams();
  }, []);

  return (
    <div className={classes.root}>
      {loading.isLoading ? (
        <div className={classes.center}>
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            tip={loading.tip}
          />
        </div>
      ) : (
        <Chat
          className={classes.chat}
          client={client}
          render={({
            getSubscribedChannels,
            loadingChannels,
            channels,
            getChannelBySid,
            loadingChannel,
            channel,
            user,
          }) => {
            return (
              <Layout
                siderContent={SiderContent}
                mainContent={MainContent}
                getSubscribedChannels={getSubscribedChannels}
                loadingChannels={loadingChannels}
                channels={channels}
                getChannelBySid={getChannelBySid}
                loadingChannel={loadingChannel}
                channel={channel}
                user={user}
                initial={initial}
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default Home;

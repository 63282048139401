/* eslint-disable no-use-before-define */
import Chat from './chat';
import Channel from './channel';
import Message from './message';
import User from './user';
import Member from './member';

/**
 * The Wrapper function is used to define custom behavior for fundamental operations (e.g. property lookup, assignment, enumeration, function invocation, etc).
 * @param {Object} wrapper
 */
function Wrapper(wrapper) {
  return (instance) => {
    const proxy = new Proxy(instance, {
      get(target, prop) {
        if (wrapper[prop]) {
          const original = target[prop];
          if (original instanceof Function) {
            return (...args) => wrapper[prop](target, ...args);
          }
          return wrapper[prop](target);
        }
        return target[prop];
      },
    });
    return proxy;
  };
}

const ChannelWrapper = {
  getMessages: async (channel, pageSize, anchor, direction) => {
    const paginator = await channel.getMessages(pageSize, anchor, direction);
    return Wrapper(PaginatorWrapper)(paginator);
  },
  on: (client, event, listener) => {
    client.on(event, (...params) => {
      if (!params || params.length === 0) {
        return listener();
      }
      const wrappedParams = params.map((param) => {
        switch (params.constructor.name) {
          case 'Channel':
            return Wrapper(ChannelWrapper)(param);
          case 'Message':
            return Wrapper(MessageWrapper)(param);
          case 'Member':
            return Wrapper(MemberWrapper)(param);
          default:
            return param;
        }
      });
      return listener(...wrappedParams);
    });
  },
  ...Channel,
};

const UserWrapper = { ...User };

const MemberWrapper = { ...Member };

const MessageWrapper = {
  channel: (message) => Wrapper(ChannelWrapper)(message),
  ...Message,
};

const PaginatorWrapper = {
  items: (paginator) => {
    const result = paginator.items.map((item) => {
      switch (item.constructor.name) {
        case 'Channel':
          return Wrapper(ChannelWrapper)(item);
        case 'Message':
          return Wrapper(MessageWrapper)(item);
        default:
          return item;
      }
    });
    return result;
  },
  prevPage: async (paginator) => {
    const instance = await paginator.prevPage();
    return Wrapper(PaginatorWrapper)(instance);
  },
  nextPage: async (paginator) => {
    const instance = await paginator.nextPage();
    return Wrapper(PaginatorWrapper)(instance);
  },
};

const ChatWrapper = {
  user: (client) => {
    return Wrapper(UserWrapper)(client.user);
  },
  getChannelBySid: async (client, channelSid) => {
    const channel = await client.getChannelBySid(channelSid);
    return Wrapper(ChannelWrapper)(channel);
  },

  getChannelByUniqueName: async (client, uniqueName) => {
    const channel = await client.getChannelByUniqueName(uniqueName);
    return Wrapper(ChannelWrapper)(channel);
  },

  getSubscribedChannels: async (client) => {
    const paginator = await client.getSubscribedChannels();
    const result = paginator.items.map((item) => {
      return Wrapper(ChannelWrapper)(item);
    });
    paginator.items = result;
    return paginator;
  },

  on: (client, event, listener) => {
    client.on(event, (...params) => {
      if (!params || params.length === 0) {
        return listener();
      }
      const wrappedParams = params.map((param) => {
        switch (params.constructor.name) {
          case 'Channel':
            return Wrapper(ChannelWrapper)(param);
          case 'Message':
            return Wrapper(MessageWrapper)(param);
          default:
            return param;
        }
      });
      return listener(...wrappedParams);
    });
  },
  ...Chat,
};

export default Wrapper(ChatWrapper);

import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    height: '100vh',
    '@global': {
      '.ant-layout-sider-children': {
        overflow: 'auto',
        background: '#fff',
        borderRight: '1px solid #e8e8e8',
      },
      '.ant-layout-header': {
        display: 'flex',
        padding: [12, 12],
        background: '#fff',
        lineHeight: 'initial',
      },
      '.ant-layout-content': {
        height: '100%',
      },
    },
  },
});

export default useStyles;

import * as configs from '../../configs';
import * as network from '../../network';
import * as storage from '../../storage';

// eslint-disable-next-line import/prefer-default-export
export const takeControl = async ({ channelSid, status }) => {
  const token = storage.get('TOKEN');
  return network.request(
    network.makeUrl(configs.getConfig('SVC_GCP'), 'auth/admin/channels/status'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        channel_id: channelSid,
        status,
      }),
    },
  );
};

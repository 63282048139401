export const changeControlStatus = ({ channelSid, status }) => {
  return {
    type: 'home/changeControlStatus',
    payload: {
      channelSid,
      status,
    },
  };
};

export const changeControlStatusFail = (error) => {
  return {
    type: 'changeControlStatusFail',
    payload: {
      error,
    },
  };
};

export const changeControlStatusSuccess = (data) => {
  return {
    type: 'changeControlStatusSuccess',
    payload: {
      data,
    },
  };
};

export const cleanupControlStatus = () => {
  return {
    type: 'home/cleanupControlStatus',
  };
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { ChannelList } from '@mannegobot/nego-test-lib';
import useStyles from './styles';

const SiderContent = ({
  loadingChannels,
  channels,
  getSubscribedChannels,
  getChannelBySid,
  initial,
}) => {
  const classes = useStyles();
  const { defaultChannel } = initial || {};
  let defaultChannelId = defaultChannel && defaultChannel.channelId;
  if (channels && channels.length > 0 && !defaultChannel) {
    defaultChannelId = channels[0].sid;
  }

  useEffect(() => {
    const initChannelList = async () => {
      await getSubscribedChannels();
    };
    initChannelList();
  }, []);

  const onChannelSelectHandler = async (event) => {
    await getChannelBySid(event.key);
  };

  return (
    <div className={classes.root}>
      <ChannelList
        loading={loadingChannels}
        channels={channels}
        onSelectChannel={onChannelSelectHandler}
        defaultChannel={defaultChannelId}
      />
    </div>
  );
};

export default SiderContent;

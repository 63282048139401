import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    background: '#fff',
    overflow: 'auto',
    '@global': {
      '.ant-menu': {
        borderRight: 'none',
      },
    },
  },
});

export default useStyles;

import { createSelector } from 'reselect';

export const getHome = (state) => {
  return state.home || {};
};

export const getControlStatus = createSelector(getHome, (home) => {
  return {
    status: home.controlStatus,
    state: home.controlState,
  };
});

import configdefault from './config';
import configdev from './config.dev';
import configstaging from './config.staging';
import * as storage from '../storage';

const evnConfig = {
  dev: configdev,
  staging: configstaging,
};

const config = {
  data: {
    ...configdefault,
  },
};

/**
 * Call this function to reload the config if the env change
 */
export const loadConfig = () => {
  const env = storage.get('ENV', process.env.REACT_APP_ENV);
  config.data = {
    ...configdefault,
    ...evnConfig[env],
  };
};

/**
 * Call this function to get the config.
 */
export const getConfig = (key) => {
  return config.data[key];
};
